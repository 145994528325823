import React from 'react';
import Banner from '../../Homepage/banner';
import MoneyComplicated from '../../Homepage/moneyComplicated';
import RulesForStress from '../../Homepage/rulesForStress';
import WorkSection from '../../Homepage/workSection';
import WhyNab from '../../Homepage/whyNab';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
    <Helmet>
        <title>Be in control of your finances– Simplify MONEY with Budzet</title>
        <meta
          name="description"
          content="Seeking Financial Freedom but tired of not seeing any results? Take control of your finances—budget smarter, save better, and make every dollar count with Budzet. Signup Now!"
        />
      </Helmet>
      <Banner/>
      <MoneyComplicated/>
      <RulesForStress/>
      <WorkSection/>
      <WhyNab/>
    </>
  );
}

export default Home;
