import React from 'react';
import { Helmet } from 'react-helmet';

const TermAndCondition = () => {
  return (
    <div className="InnerPage">
     <Helmet>
        <title>Terms & Conditions - Budzet</title>
        <meta
          name="description"
          content="Read Our Terms and Conditions for using our budgeting tools and financial wellness platform."
        />
      </Helmet>
      <div className="InnerPageBanner">
        <div className="container">
          <h1>Term And Condition</h1>
        </div>
      </div>
      <div className="PrivacyPolicypage">
        <div className="container">
          <div className="PrivacyPolicypagein">
            <div className="newcontentprivacypolicy">
              <h3>The Standard Terms and Conditions</h3>
              <p>
                The Standard Terms and Conditions mentioned on this page shall determine, manage and regulate your use of our remittance services and our website/s, app, www.afno.io other allied websites and the services that we provide. These Terms and Conditions shall act as an agreement between you, our sender, the recipient, AFNO and its subsidiaries/originating partners All of the terms mentioned on this page shall be applied with full affect. By using our App, website and our services, you have agreed to accept all the Terms and Conditions that have been mentioned on this page.
              </p>
              <h4>1. Definitions and Meanings:</h4>
              <p>1.1. Remittances for this site is generated by AFNO and its partners/subsidiaries from different legal entities in different parts of the world:</p>
              <p>1.2. AFNO app and website: These refer to www.afno.io and other apps that we provide the services of cross border payments and remittances.</p>
              <p>1.3. Terms and Conditions: These refer to all of the points of information, rule, regulations and amendments mentioned on this page and act as an agreement between the Clients and the organization.</p>
              <p>1.4. Customer Service: This refers to the availability of assistance and advice provided by our company to our Clients which can be reached via email support@afnocorp.com or by calling our tollfree number in the US: +1-888-226-0712.</p>
              <p>1.5. Privacy Policy: This refers to the policies adopted by AFNO and its partners/subsidiaries in regard to processing and regulating the personal and company data provided to us. These policies shall be further clarified throughout this page.</p>
              
              <h4>2. AFNO Financial Holdings Corporation Overview:</h4>
              <p>2.1. AFNO was incorporated on March 9, 2021. It has signed various agreements with its origination partners/subsidiaries to enable it to start the remittance from USA, Canada, European Union countries, United Kingdom, South Korea and other parts of the world.</p>
              
              <h4>3. About the Terms and Conditions:</h4>
              <p>3.1. These Terms and Conditions regulate the use of all the services provided by AFNO and its partners/subsidiaries - whether it be face to face, online, via telephone or any other means of providing service.</p>
              <p>3.2. AFNO and its partners/subsidiaries primarily provide services to transfer money between countries. These Terms and Conditions solidify this claim.</p>
              
              <h4>4. AFNO A/C Opening:</h4>
              <p>4.1. The procedure to sign-up to AFNO and its originating partners/subsidiaries requires you, our client, to provide all of the necessary details mentioned in our sign-up form and also to accept these Terms and Conditions. The type and detail of information required can vary according to the additional services requested by you from our organization.</p>
              <p>4.2. All the information provided during the sign-up and verification process, as well as all the additional data that may be required for particular services, must be accurate and correct.</p>
              <p>4.3. The use and regulation of this information shall be managed under the Terms and Conditions of our organization and the governing law of US and other countries that you sign up from as per the various companies that we have registered in different countries. This shall be further clarified below.</p>
              
              <h4>5. Client Account Maintenance and Safety:</h4>
              <p>5.1. The Client must ensure that all the information provided to AFNO and its partners/subsidiaries is accurate, correct and up-to-date.</p>
              <p>5.2. The organization may communicate with the Client using the data they have provided. It is the duty of the customer to read any messages sent by the organization.</p>
              <p>5.3. The Client can track all of their transactions on AFNO via their respective mobile app or customer portal on www.afno.io.</p>
              <p>5.4. Along with transactions processed, all fees charged are also shown in the Phone app and/or customer portal. The Client should regularly check their AFNO Account and transaction statement and report to our Customer Service in the occurrence of any irregularities or if they wish to get clarification.</p>
              <p>5.5. If the Client claims a refund for an unauthorized transaction or incorrectly executed transfer on AFNO they must notify our organization as soon as they become aware of the situation and no later than thirty (30) days from the date of the transaction/transfer.</p>
              <p>5.6. It is the responsibility of the Client to take any reasonable steps in ensuring the secrecy of their login details. The Client should never disclose their login details to anybody.</p>
              <p>5.7. The Client should change their login password in regular intervals (every 3-6 months) to reduce the possibility of a security breach.</p>
              <p>5.8. In the case that the Client suspects that their login details have been lost, stolen or altered, they should immediately contact Customer Service and file a report.</p>
              <p>5.9. AFNO and its partners/subsidiaries holds and may exercise the right to suspend/close or limit some of the functionalities of the Client’s account in the case that it is suspected that any of the security features of the organization have been compromised. In this case, we will inform the Client about this restriction or suspension as soon as possible.</p>
              <p>5.10. AFNO and its partners/subsidiaries hold the right to suspend entirely or limit some of the functionalities of a Client’s account on reasonable grounds relating to the unauthorized or fraudulent use of its services or if the Terms and Conditions have been breached by the Client.</p>
              
              <h4>6. AFNO Account Closing:</h4>
              <p>6.1. The Client has the right to request for the termination of their account any time that they see fit. In order to do so, the Client must contact the Customer Service and follow further instructions.</p>
              <p>6.2. If the Client’s account shows a positive balance at the time of its closure, we will arrange funds to be transferred to an external account. This external account’s account holder name must be as per the Client’s AFNO holder name.</p>
              <p>6.3. AFNO and its partners/subsidiaries hold the right to carry out any necessary money laundering, terrorism financing, fraud or other illegal activity checks before authorizing any withdrawal of Client’s funds, including in relation to returning any funds to the Client after the closure of the AFNO Account.</p>
              
              <h4>7. Receiving Funds to AFNO Account:</h4>
              <p>7.1. The Client can transfer funds either paying in card or bank transfer from their own card/bank account only. AFNO and its partners/subsidiaries will only accept funds received from the Client, thus excluding third party payments. In order for us to facilitate the transfer, the Client must state the reference code as the description of the transfer.</p>
              <p>7.2. Limits for receiving funds are defined depending on the Client’s account type. Please consider the section “AFNO Types” of these Terms and Conditions for more details.</p>
              <p>7.3. Receiving funds are subject to fees. Please refer to the section “AFNO Fees” of these Terms and Conditions for more details.</p>
              <p>7.4. AFNO and its partners/subsidiaries hold the right to carry out any necessary money laundering, terrorism financing, fraud or other illegal activity checks before authorizing the transaction and crediting funds to the Client’s account.</p>
              
              <h4>8. Sending Funds from AFNO:</h4>
              <p>8.1. The Client will be asked to provide details of the recipient of the funds in order to initiate the transfer of funds. The Client must take great care to properly type the exact details of who they wish to send the money to. AFNO and its partners/subsidiaries shall not be liable for any error the Client makes when entering the recipient’s details.</p>
              <p>8.2. Sending funds from AFNO is subject to fees and limits. Please consider the section “Fees” of these Terms and Conditions for more details.</p>
              <p>8.3. Recipients of the Client’s transfers must be in compliance with any applicable laws and regulations. It is the Client’s responsibility to ensure the appropriate level of controls and ensure the compliance of the transaction. In case of any doubts, the payment request should not be processed.</p>
              <p>8.4. AFNO and its partners/subsidiaries reserve the right to carry out any necessary anti-money laundering, terrorist financing, fraud or other illegal activity checks before authorizing the transaction and debiting the Client’s account.</p>
              
              <h4>9. AFNO Types:</h4>
              <p>9.1. AFNO and its partners/subsidiaries offer two types of accounts: free account, and paid account. For free accounts, the main limitation is lower transaction and balance limits. Please contact our Customer Service for the limit for free account.</p>
              <p>9.2. The fees and limits for both free and paid accounts are explained in the section “AFNO Fees” of these Terms and Conditions.</p>
              <p>9.3. AFNO and its partners/subsidiaries hold the right to refuse the Client to change the type of their account, to be expected to need a proof of verification of identity and when applicable the Corporate identity. Such refusals are made on reasonable grounds that are fully related to their transfer of knowledge and its rules with USAA then also Customers. Account may. St. service. The Client is strictly forbidden from engaging in data mining, data harvesting, data extracting or any other similar activity in relation to this Website.</p>
              <p>9.4. If the Client attempts or conducts any transaction in violation of the prohibited transactions mentioned in these Terms and Condition, we will reserve the right to: suspend and/or close the Client’s AFNO SERVICES, reverse any relative transactions and/or report the transaction and the Client’s account to the relevant law enforcement agency.</p>
              <p>9.5. The recipient(s) of Client’s transfers must be in compliance with any applicable laws and regulation. It is the Client’s responsibility to ensure the appropriate level of controls and to ensure the compliance of the transaction with the respective Terms and Conditions and laws. In case of any doubt, the payment request shall not be processed and/or completed.</p>
              
              <h4>10. Payment of Fees:</h4>
              <p>10.1. The full list of fees applicable to the Client will be listed in the app and/or the website before making the transaction.</p>
              <p>10.2. All of the aforementioned fees are subject to change as per the organization’s requirements or as per the laws of Nepal.</p>
              <p>10.3. Fees will be deducted from the Client’s AFNO ACCOUNT. If any transaction fees apply, they shall be debited from the Client’s account as soon as the transaction is executed. In the case of insufficient funds being available in the Client’s account, the transaction shall be refused.</p>
              
              <h4>11. Transaction Limits:</h4>
              <p>11.1. The transaction limits for receiving and sending funds are set as per Client, based on individual profiles/accounts. The Client should be aware of the limitations in place before executing any transactions.</p>
              
              <h4>12. Intellectual Property Rights:</h4>
              <p>12.1. Other than the content you (the Client) own, under these Terms and Conditions, AFNO and its partners/subsidiaries /or its licensors own all the intellectual property rights and materials contained in this Website.</p>
              <p>12.2. You are granted limited license only for purposes of viewing the material contained on this Website and for using the services of our organization.</p>
              
              <h4>13. Regulating Client Data:</h4>
              <p>13.1. AFNO Privacy Policy regulates and controls the manner in which our organization collects, processes, manages, uses and protects the data you decide to share with us. By agreeing to these Terms and Conditions, you also agree to our privacy policy and consent on our handling of the data you provide to us.</p>
              
              <h4>14. Electronic Fund Transfers (EFTs) and Account Balances.</h4>
              <p>14.1. AFNO and its partners/subsidiaries partner with financial services software company Sila Inc. (Sila) and banking services provider Evolve Bank & Trust (Evolve), member FDIC, to offer you electronic fund transfers (EFTs). By creating an AFNO account and initiating bank deposits or withdrawals, you agree to Sila’s terms of service, https://silamoney.com/terms-of-service/, and Evolve’s demand deposit account agreement, https://silamoney.com/evolve-bank-deposit-agreement/, (together, the “Partner Terms”). You must comply with the Partner Terms when creating or using your Unlimited Cloud LLC Account. The Partner Terms may be modified from time to time, and the governing versions are incorporated by reference into these Terms & Conditions. Any term not defined in this section but defined in the Partner Terms assumes the meaning as defined in the Partner Terms. IT IS YOUR RESPONSIBILITY TO READ AND UNDERSTAND THE PARTNER TERMS BECAUSE THEY CONTAIN TERMS AND CONDITIONS CONCERNING YOUR UNLIMITED CLOUD LLC ACCOUNT, INCLUDING BUT NOT LIMITED TO LIMITATIONS, REVERSALS, AND ARBITRATION PROVISIONS, AND YOUR RELEVANT RIGHTS AND LIABILITIES.</p>
              
              <h4>15. AFNO Liability:</h4>
              <p>15.1. We will refund the payment amount and all deducted fees in the case of an unauthorized or an incorrectly executed payment due to an error on our side. This does not apply in the following situations:</p>
              <p>15.1.1. If the unauthorized payment is a result of your failure to keep your AFNO account login details secure.</p>
              <p>15.1.2. If you do not notify us within a reasonable time period after you have gained knowledge about any loss of your AFNO account login details or any other situation or occurrence that may prejudice your account’s security. In this situation you will be liable for losses incurred up to your notification to us.</p>
              <p>15.1.3. If you compromise the security of your AFNO account with intent or gross negligence, thus resulting in an unauthorized transaction, you are to be considered solely liable for all losses.</p>
              <p>15.1.4. If you do not inform us or bring to our attention an unauthorized or incorrect transaction by thirty (30) days from the transaction date, you will solely liable for all losses.</p>
              <p>15.2. If you notify us of any suspicious behavior on your account and after this notification an unauthorized or incorrect transaction is to occur, we shall remain liable and refund the amount immediately to you.</p>
              <p>15.3. In case our service or our intermediary services are disrupted, we shall not be liable if the disruption is related to unforeseeable circumstances beyond our reasonable control.</p>
              <p>15.4. AFNO and its partners/subsidiaries shall not be responsible for any losses connected to our compliance with legal and regulatory requirements and we shall not be liable for any indirect or consequential losses as loss of profit, loss of business and loss of reputation.</p>
              <p>15.5. AFNO and its partners/subsidiaries are to be considered obliged under these Terms and Conditions only in relation to providing money transfer services and ancillary foreign exchange services.</p>
              <p>15.6. You will be liable to compensate and reimburse us in case we incur or suffer losses from you breach of these Terms and Conditions.</p>
              <p>15.7. We shall not be liable for the assessment or payment of any taxes, duties or other charges that arise from the underlying commercial transaction between you and another AFNO client.</p>
              
              <h4>16. Termination and Suspension:</h4>
              <p>16.1. AFNO and its partners/subsidiaries hold the right to suspend your account with no notice in the following cases:</p>
              <p>16.1.1. For security reasons and if we suspect that your AFNO account has been somehow compromised.</p>
              <p>16.1.2. In case we believe or suspect that your AFNO account has been used without your knowledge or fraudulently. In this case we will notify you before we process the account suspension. If prior notification is not possible, we will inform you as soon as possible after suspension unless such notification is prohibited by law.</p>
              <p>16.2. AFNO and its partners/subsidiaries hold the right to terminate your account with no notice in the following cases:</p>
              <p>16.2.1. If any of the conditions indicated in these Terms and Conditions are breached by you.</p>
              <p>16.2.2. If we believe or we have proof that you are in violation of any law or regulation applicable to the use of our services.</p>
              <p>16.2.3. If we believe or we have proof that you are involved in any way in a fraudulent activity, money laundering or other criminal activity.</p>
              
              <h4>17. Handling of Complaints:</h4>
              <p>17.1. If you wish to make a complaint about any aspect of AFNO and its partners/subsidiaries you may submit your complaint by sending an email to our Customer Service; email address: support@afnocorp.com.</p>
              <p>17.2. We will acknowledge the reception of your complaint within 2 business days by email. The complaint will be recorded on our system and we will investigate your complaint and come back to you with the results of our investigation no later than thirty (30) business days of receipt of your complaint.</p>
              
              <h4>18. Indemnification:</h4>
              <p>18.1. You hereby indemnify to the fullest extent AFNO and its partners/subsidiaries against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
              
              <h4>19. Severability:</h4>
              <p>19.1. If any provision of these Terms and Conditions is found to be invalid or unenforceable under any applicable law of the country, such provisions shall be deleted without affecting the remaining provisions herein.</p>
              
              <h4>20. Variations to the Agreement:</h4>
              <p>20.1 AFNO and its partners/subsidiaries are permitted to revise and amend these Terms and Conditions at any given time as seen fit by the organization.</p>
              <p>20.2. Any changes made to the Terms and Conditions shall be notified to you via email.</p>
              <p>20.3. As a user of our website and services, you (our Client), will be expected to review these terms on a regular basis.</p>
              
              <h4>21. Origination Partner:</h4>
              <p>21.1 AFNO is an affiliate and originates its various remittances from its partner company: Unlimited Cloud LLC responsible for governing operations in the United States, and having its office registered at 10685-B Hazelhurst Dr #18459, Houston, TX 77043, USA. The Unlimited Cloud LLC Privacy Policy and Refund/Cancellation/Return policy is the same as the referenced here. Please call +1-888-226-0712 for a customer service representative pertaining to services in the United States.</p>
              
              <h4>22. Government Law and Jurisdiction:</h4>
              <p>22.1. The Terms and Conditions mentioned in this page are governed and interpreted in accordance with the laws of USA and shall be subject to changes and amendments in accordance with the law.</p>
              <p>22.2. The settlement of any disputes between the Clients and the organization shall be made in accordance with the aforementioned Terms and Conditions and the laws of the country.</p>
              
              <h4>23. Electronic Fund Transfers (EFTs) and Account Balances:</h4>
              <p>23.1. AFNO and its partners/subsidiaries partner with financial services software company Sila Inc. (Sila) and banking services provider Evolve Bank & Trust (Evolve), member FDIC, to offer you electronic fund transfers (EFTs). By creating an AFNO account and initiating bank deposits or withdrawals, you agree to Sila’s terms of service, https://silamoney.com/terms-of-service/, and Evolve’s demand deposit account agreement, https://silamoney.com/evolve-bank-deposit-agreement/, (together, the “Partner Terms”). You must comply with the Partner Terms when creating or using your Unlimited Cloud LLC Account. The Partner Terms may be modified from time to time, and the governing versions are incorporated by reference into these Terms & Conditions. Any term not defined in this section but defined in the Partner Terms assumes the meaning as defined in the Partner Terms. IT IS YOUR RESPONSIBILITY TO READ AND UNDERSTAND THE PARTNER TERMS BECAUSE THEY CONTAIN TERMS AND CONDITIONS CONCERNING YOUR UNLIMITED CLOUD LLC ACCOUNT, INCLUDING BUT NOT LIMITED TO LIMITATIONS, REVERSALS, AND ARBITRATION PROVISIONS, AND YOUR RELEVANT RIGHTS AND LIABILITIES.

            </p>
          </div>
        </div>
      </div>
    </div>

</div>
  );
};

export default TermAndCondition;
