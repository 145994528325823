import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="InnerPage">
      <div className="InnerPageBanner">
        <div className="container">
          <h1>Privacy Policy</h1>{" "}
        </div>
      </div>
      <div className="PrivacyPolicypage">
        <div className="container">
          <div className="PrivacyPolicypagein">
            <div className="newcontentprivacypolicy privacy-policy">
              <h3>COMPANY OVERVIEW</h3>
              <p className="text-left pb-4">
                AFNO™ powered by POCKETNEST™- herein referred to as BUDZET or US
                is a financial wellness platform that automates savings,
                investments, and budgeting to forge deeper connections between
                people and their financial institutions to help them achieve
                financial wellness. Made by and for millennials and Gen-Xers, it
                brings institutions rich member data and insights to reach
                younger audiences more efficiently, serve more people, identify
                more cross-sell opportunities, and promote holistic financial
                wellness. BUDZET licenses its comprehensive financial planning
                software to institutions like banks, credit unions, investment
                advisors, 401k plan sponsors and employee wellness platforms.
                Learn more at afno.io. We care about our users’ privacy and want
                to clearly explain how we collect, use and share our users’
                personal information. This privacy policy applies to our end
                users (further called “you”) and our institutional partners and
                is part of our terms of service.
              </p>

              <p className="text-left pb-4">INFORMATION PROVIDED BY USERS</p>
              <p className="text-left pb-4">
                The private information that you provide to us includes your
                name, email address, date of birth, state of residency and
                information about your children. With the exception of email
                address and state of residency, this information is optional and
                not required to use the application.
              </p>
              <p className="text-left pb-4">All Accounts</p>
              <p className="text-left pb-4">
                To create a BUDZET account, you must provide your email address
                and state of residency.
              </p>
              <p className="text-left pb-4">Our Users</p>
              <p className="text-left pb-4">
                A BUDZET user is someone who creates an account with BUDZET. We
                encourage you to complete as many of the BUDZET assessments as
                possible and link your financial accounts to provide the most
                customized recommendations.
              </p>
              <p className="text-left pb-4">INFORMATION WE COLLECT</p>
              <p className="text-left pb-4">
                We collect information automatically as you navigate the
                platform. We may store usage information such as the type of
                device you use, your operating system, browser type, IP address,
                and device ID, the pages you visit or request, links clicked,
                referring sites and your interactions. We use Google Analytics
                and Segment to see how you use BUDZET so we can improve our
                platform. We encourage you to read the Google privacy policy. If
                you prefer not to have data reported by Google Analytics, you
                can install the Google Analytics Opt-out Browser Add-on. We also
                encourage you to read more about Segment and its privacy policy.
              </p>
              <p className="text-left pb-4">HOW WE USE YOUR INFORMATION</p>
              <p className="text-left pb-4">
                We never sell your information to anyone.
              </p>
              <p className="text-left pb-4">We use your information to:</p>
              <ul className="text-left pb-4">
                <li>Provide advice to you</li>
                <li>Allow you to sign in to your account</li>
                <li>
                  Send you emails relevant to your usage, as controlled by your
                  preferences.
                </li>
                <li>Understand how you use our platform</li>
              </ul>
              <p className="text-left pb-4">
                INFORMATION SHARED WITH YOUR FINANCIAL INSTITUTION
              </p>
              <p className="text-left pb-4">
                If you have accessed our platform, then you are an active
                customer, and the information you add to this platform will be
                shared with us and us alone. We may use this information to sell
                you additional products and services. That information includes:
              </p>
              <ul className="text-left pb-4">
                <li>
                  The information you share with the platform, including your
                  email address, name, and date of birth
                </li>
                <li>
                  Details about your personal financial planning situation
                  including information about your income tax, estate, cash
                  flow, net worth, debt, college savings, insurance, investment,
                  retirement and financial plan
                </li>
                <li>
                  Some aggregated and anonymized data about how you use our
                  platform that cannot be linked back to any individual user
                </li>
              </ul>
              <p className="text-left pb-4">
                INFORMATION SHARED WITH YOUR EMPLOYER INSTITUTION
              </p>
              <p className="text-left pb-4">
                If you have accessed BUDZET as an employee benefit from your
                employer or another, nonfinancial institution, then your
                personal information, or any details about your financial
                situation will not be shared with these institutions. We may
                share some aggregated and anonymized data about how you use our
                platform that cannot be linked back to any individual user.
              </p>
              <p className="text-left pb-4">
                INFORMATION SHARED WITH THIRD PARTIES
              </p>
              <p className="text-left pb-4">
                Personally Identifiable Information (PII) is data that includes
                a personal identifier like your name, email or address, or data
                that could reasonably be linked back to you. We do not share
                this data with any third parties. We may release data to protect
                the security and integrity of BUDZET and to protect the rights,
                property, or safety of our company, its employees, users, or
                others if we believe that disclosure is reasonably necessary to
                comply with a law, regulation, or valid legal process (e.g.,
                subpoenas or warrants served on us). If we are going to release
                your data, we will do our best to provide you with notice in
                advance by email unless we are prohibited by law from doing so.
                We may release data in connection with the sale, merger,
                bankruptcy, sale of assets or reorganization of our company. We
                will notify you if a different company receives your PII. The
                promises in this privacy policy apply to any data transferred to
                a new entity.
              </p>
              <p className="text-left pb-4">YOUR PREFERENCES AND DATA RIGHTS</p>
              <p className="text-left pb-4">Choosing Your Preferences</p>
              <p className="text-left pb-4">
                The Settings link for BUDZET is located in the app at the bottom
                of the toolbar after login. Settings let’s you view and change
                your preferences.
              </p>
              <p className="text-left pb-4">Exercising Your Data Rights</p>
              <p className="text-left pb-4">
                You can exercise rights over your data in the following ways:
              </p>
              <ul className="text-left pb-4">
                <li>
                  Modifying the Information in Your Account – You can modify or
                  delete certain information associated with your account in
                  settings.
                </li>
                <li>
                  Disabling Your Account – You can permanently disable your
                  account by emailing{" "}
                  <a href="mailto:support@pocketnest.com">
                    support@pocketnest.com
                  </a>{" "}
                  and requesting that we do so. Please be aware that this is a
                  final act, and you cannot log into your account again after
                  taking this action. When you email us requesting to do so, we
                  will remove your ability to log in within 30 days and delete
                  all data on our servers associated with your account. Even if
                  an account is deleted, some information may be retained by our
                  analytics providers on their own servers.
                </li>
                <li>
                  Removing Marketing Emails – You can stop receiving marketing
                  emails by clicking the unsubscribe link from the bottom of any
                  email sent to you.
                </li>
              </ul>
              <p className="text-left pb-4">
                Understanding Our Data Retention Periods
              </p>
              <p className="text-left pb-4">
                We retain your information for ten years unless you disable your
                account information from your account settings. We may keep some
                of your Personal Information after you close your account to
                comply with our legal obligations (including law enforcement
                requests), meet regulatory requirements, resolve disputes,
                maintain security, prevent fraud and abuse, enforce our Terms
                and Conditions, or fulfill your request to "unsubscribe" from
                further messages from us. We may retain de-identified
                information after your account has been closed. Our company
                securely stores your data on our Amazon AWS infrastructure,
                which is always encrypted while you are at rest and in flight.
              </p>
              <p className="text-left pb-4">
                What are your data protection rights? Every user is entitled to
                the following and requests can be made by emailing{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
                .
              </p>
              <ul className="text-left pb-4">
                <li>
                  The right to access – You have the right to request copies of
                  your personal data. We may charge you a small fee for this
                  service.
                </li>
                <li>
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate.
                </li>
                <li>
                  The right to erasure – You have the right to request that Our
                  Company erase your personal data under certain conditions. If
                  you make a request, we have one month to respond to you.
                </li>
              </ul>
              <p className="text-left pb-4">
                If you would like to exercise any of these rights, please
                contact us at our email{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
                .
              </p>
              <p className="text-left pb-4">Cookies</p>
              <p className="text-left pb-4">
                Cookies are text files placed on your computer to collect
                standard Internet log information and visitor behavior
                information. When you visit our websites, we may collect
                information from you automatically through cookies or similar
                technology. For further information, visit allaboutcookies.org.
              </p>
              <p className="text-left pb-4">How do we use cookies?</p>
              <p className="text-left pb-4">
                Our Company uses cookies in a range of ways to improve your
                experience on our website, including:
              </p>
              <ul className="text-left pb-4">
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
              </ul>
              <p className="text-left pb-4">What types of cookies do we use?</p>
              <p className="text-left pb-4">
                There are several different types of cookies, however, our
                website uses:
              </p>
              <p className="text-left pb-4">
                Functionality – Our Company uses these cookies so that we
                recognize you on our website and remember your previously
                selected preferences. These could include what language you
                prefer and the location you are in. A mix of first-party and
                third-party cookies is used.
              </p>
              <p className="text-left pb-4">
                Advertising – Our Company uses these cookies to collect
                information about your visit to our website, the content you
                viewed, the links you followed and information about your
                browser, device, and your IP address. Our Company sometimes
                shares some limited aspects of this data with third parties for
                advertising purposes. We may also share online data collected
                through cookies with our advertising partners. This means that
                when you visit another website, you may be shown advertising
                based on your browsing patterns on our website.
              </p>
              <p className="text-left pb-4">How to manage cookies</p>
              <p className="text-left pb-4">
                You can set your browser not to accept cookies, and the above
                website tells you how to remove cookies from your browser.
                However, in a few cases, some of our website features may not
                function as a result.
              </p>
              <p className="text-left pb-4">
                Additional Questions Related to Privacy
              </p>
              <p className="text-left pb-4">
                For additional privacy-related questions about your account, you
                may email us at{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
              </p>
              <p className="text-left pb-4">
                USERS WITHIN THE UNITED STATES OF AMERICA
              </p>
              <p className="text-left pb-4">
                We currently only offer services to individuals in the United
                States of America or US Citizens outside of the US. We are based
                in the United States.
              </p>
              <p className="text-left pb-4">CHILDREN</p>
              <p className="text-left pb-4">
                BUDZET is not directed at children under the age of 13, and they
                may not create an account or otherwise use our platform.
              </p>
              <p className="text-left pb-4">CHANGES</p>
              <p className="text-left pb-4">
                We may sometimes make changes to this policy. If we make
                material changes that adversely affect your rights under this
                policy, we will let you know by posting an announcement on the
                site or sending you an email prior to the changes coming into
                effect. Continuing to use BUDZET after a change to this policy
                means you accept the new policy.
              </p>
              <p className="text-left pb-4">AUTHORIZED SUB PROCESSORS</p>
              <p className="text-left pb-4">
                BUDZET may engage and use processors with access to certain
                Customer Data (each, a "Sub processor"). The following includes
                information about the identity, location, and role of each Sub
                processor. We use third party Sub processors to provide
                infrastructure services, improve user experience, and help us
                provide customer support and email support.
              </p>

              <div className="tableui">
                <table>
                  <thead>
                    <tr>
                      <th>Sub-Processor</th>
                      <th>Purpose</th>
                      <th>Data Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Amazon Services</td>
                      <td>Web Hosting & Infrastructure</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>Google, Inc.</td>
                      <td>Cloud-based Email Service Provider</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>HubSpot, Inc.</td>
                      <td>Marketing Platform</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>Google Analytics & Segment</td>
                      <td>Marketing & Engagement Platform</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>Plaid</td>
                      <td>Financial data aggregator</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>Payitoff</td>
                      <td>Student debt management</td>
                      <td>United States</td>
                    </tr>
                    <tr>
                      <td>FindMoney</td>
                      <td>Rewards and coupon platform</td>
                      <td>United States</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="text-left pb-4">PLAID</p>
              <p className="text-left pb-4">
                We link accounts through a third-party financial data integrator
                Plaid, and we encourage you to read Plaid’s{" "}
                <a href="">privacy policy</a>{" "}
                here. Plaid has millions of users, and this linking allows
                BUDZET to assemble a complete financial profile and make
                customized recommendations for our users through secure
                tokenization.
              </p>
              <p className="text-left pb-4">
                BUDZET only stores data crucial to users’ financial wellness
                journey—with explicit user permission. Securely stored in
                partnership with one of the largest data aggregators, Plaid,
                this data enables BUDZET to provide users with a highly
                interactive and productive app experience that delivers
                personalized education and recommendations to grow their
                financial wellness. By consenting to this, users acknowledge and
                agree that their data may be used to optimize the functionality
                and customization of the BUDZET platform.
              </p>
              <p className="text-left pb-4">Access Controls</p>
              <p className="text-left pb-4">
                Role-based access controls are enforced at each layer of
                infrastructure. Multi-factor authentication is required to
                access Plaid infrastructure. All application and user access
                logs are stored centrally and monitored.
              </p>
              <p className="text-left pb-4">Routine Tests</p>
              <p className="text-left pb-4">
                Plaid regularly undergoes both internal and external network
                penetration tests and third-party code reviews. Plaid also
                maintains a SOC 2 Type II report by testing the design and
                operational effectiveness of their Information Security program
                using independent auditors.
              </p>
              <p className="text-left pb-4">Traffic Controls</p>
              <p className="text-left pb-4">
                The Plaid API only allows client requests using strong TLS
                protocols and ciphers. Communication between Plaid
                infrastructure and financial institutions is transmitted over
                encrypted tunnels. All client communication with the Plaid API
                requires API key authentication and utilizes cryptographically
                hashed headers and timestamps to verify authenticity.
              </p>
              <p className="text-left pb-4">FINDMONEY by FINGOAL</p>
              <p className="text-left pb-4">
                We integrate with FindMoney by FinGoal, a third-party Fintech
                application that helps us identify micro-savings opportunities
                and more customized recommendations for our users. We recommend
                you read{" "}
                <a href="">
                  FinGoal’s privacy policy
                </a>{" "}
                here.
              </p>
              <p className="text-left pb-4">PAYITOFF</p>
              <p className="text-left pb-4">
                We integrate with Payitoff, a third-party fintech application
                that provides college loan account linking and restructuring
                services. Via Payitoff, users can restructure their college
                loans and find more cost-effective alternatives to their current
                loans’ structures. We recommend you read{" "}
                <a href="https://www.payitoff.io/privacy-policy">
                  Payitoff’s privacy policy
                </a>{" "}
                here.
              </p>
              <p className="text-left pb-4">ABOUT BUDZET</p>
              <p className="text-left pb-4">
                AFNO™ is a financial wellness platform that automates savings,
                investments, and budgeting to forge deeper connections between
                people and their financial institutions to help them achieve
                financial wellness. Made by and for millennials and Gen-Xers, it
                brings institutions rich member data and insights to reach
                younger audiences more efficiently, serve more people, identify
                more cross-sell opportunities, and promote holistic financial
                wellness. BUDZET licenses its comprehensive financial planning
                software to institutions like banks, credit unions, investment
                advisors, 401k plan sponsors and employee wellness platforms.
                Learn more at <a href="https://www.afno.io/">afno.io</a>.
              </p>
              <p className="text-left pb-4">Last updated October 2021</p>

              <h3>Overview of Our Privacy Policy</h3>
              <p className="text-left pb-4">
                This privacy policy pertains to Afno Financial Holdings
                Corporation, doing business as BUDZET ("we," "us," or "our"). It
                outlines the methods and reasons for which we may collect,
                store, use, and/or share ("process") your information when you
                utilize our services ("Services"). This can include when you:
              </p>
              <ul className="text-left pb-4">
                <li>
                  Visit our website located at{" "}
                  <a href="https://afno.io/">https://afno.io/</a> or any other
                  website through our subsidiaries automatically linked to this
                  privacy policy.
                </li>
                <li>
                  Download and use our mobile application (AFNO) or any other
                  application that refers to this privacy policy.
                </li>
                <li>
                  Engage with us in any other related manner, including sales,
                  marketing efforts, or events.
                </li>
              </ul>
              <p className="text-left pb-4">
                Questions or concerns? This privacy policy is intended to assist
                you in understanding your privacy rights and choices. If you do
                not accept or agree with our policies and practices, it is
                recommended to not use our Services. If you have further
                questions or concerns, please contact us at{" "}
                <a href="mailto:support@afnocorp.com">support@afnocorp.com</a>{" "}
                or{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
                .
              </p>

              <h3>SUMMARY OF KEY POINTS</h3>
              <p className="text-left pb-4">
                This section offers a brief summary of the key aspects of our
                privacy policy. You can explore more in-depth information on
                each of these topics by following the provided links or by using
                the table of contents below to locate the relevant sections.
              </p>
              <ul className="text-left pb-4">
                <li>
                  <a href="#personal-information">
                    What personal information do we process?
                  </a>
                </li>
                <li>
                  <a href="#sensitive-information">
                    Do we process any sensitive personal information?
                  </a>
                </li>
                <li>
                  <a href="#third-party-information">
                    Do we collect any information from third parties?
                  </a>
                </li>
                <li>
                  <a href="#data-processing">
                    How do we process your information?
                  </a>
                </li>
                <li>
                  <a href="#information-sharing">
                    When and with whom do we share personal information?
                  </a>
                </li>
                <li>
                  <a href="#data-safety">
                    How do we keep your information safe?
                  </a>
                </li>
                <li>
                  <a href="#your-rights">What are your rights?</a>
                </li>
                <li>
                  <a href="#exercise-rights">
                    How do you exercise your rights?
                  </a>
                </li>
              </ul>

              <h3 id="personal-information">
                What personal information do we process?
              </h3>
              <p className="text-left pb-4">
                When you visit, use, or navigate our Services, the personal
                information we process may vary based on your interactions with
                us and the Services, the choices you make, and the products and
                features you utilize. Learn more about the personal information
                you provide to us.
              </p>

              <h3 id="sensitive-information">
                Do we process any sensitive personal information?
              </h3>
              <p className="text-left pb-4">
                In some cases, we may need to process sensitive personal
                information, either with your consent or as permitted by
                applicable laws. For more information, refer to the sensitive
                information we process section.
              </p>

              <h3 id="third-party-information">
                Do we collect any information from third parties?
              </h3>
              <p className="text-left pb-4">
                We may gather information from various external sources,
                including public databases, marketing partners, and other
                third-party sources. Learn more about the information we collect
                from other sources.
              </p>

              <h3 id="data-processing">How do we process your information?</h3>
              <p className="text-left pb-4">
                We process your information to deliver, enhance, and manage our
                Services, to communicate with you, to ensure security and
                prevent fraud, and to comply with legal obligations.
                Additionally, we may process your information for other purposes
                once we have your consent. We ensure that your information is
                processed only when we have a legitimate legal reason for doing
                so. Learn more about how we process your data.
              </p>

              <h3 id="information-sharing">
                When and with whom do we share personal information?
              </h3>
              <p className="text-left pb-4">
                There are specific scenarios in which we may share your
                information with designated third parties. Visit this section:
                when and with whom we share your personal information to know
                more details.
              </p>

              <h3 id="data-safety">How do we keep your information safe?</h3>
              <p className="text-left pb-4">
                We have implemented both organizational and technical safeguards
                to protect your personal information. However, it is important
                to understand that no method of electronic transmission over the
                internet or information storage can be 100% secure. Therefore,
                we cannot guarantee that hackers, cybercriminals, or other
                unauthorized individuals will not be able to bypass our security
                measures and unlawfully access, steal, or alter your
                information. Learn more about how we take to protect your
                information.
              </p>

              <h3 id="your-rights">What are your rights?</h3>
              <p className="text-left pb-4">
                Based on where you live, certain privacy laws may provide you
                with the access to specific rights related to personal
                information. Learn more about your privacy rights.
              </p>

              <h3 id="exercise-rights">How do you exercise your rights?</h3>
              <p className="text-left pb-4">
                The most straightforward way to exercise your rights is by
                submitting a data subject access request or by reaching out to
                us directly. We will review and respond to any requests in
                compliance with the applicable data protection laws.
              </p>
              <h3>TABLE OF CONTENTS</h3>
              <ol>
                <li>What information do we gather?</li>
                <li>How do we process your information?</li>
                <li>
                  When and with whom do we share your personal information?
                </li>
                <li>Do we use cookies and other tracking technologies?</li>
                <li>Do we offer artificial intelligence-based products?</li>
                <li>How long do we keep your information?</li>
                <li>How do we keep your information safe?</li>
                <li>Do we collect information from minors?</li>
                <li>What are your privacy rights?</li>
                <li>Controls for do-not-track features</li>
                <li>
                  Do United States residents have specific privacy rights?
                </li>
                <li>Do we make updates to this policy?</li>
                <li>How can you contact us about this policy?</li>
                <li>
                  How can you review, update, or delete the data we collect from
                  you?
                </li>
              </ol>

              <h3>1. WHAT INFORMATION DO WE GATHER?</h3>
              <h3>Personal Information You Provide to Us</h3>
              <p>
                We gather personal data that you share with us. We collect the
                personal information you voluntarily choose to provide when you
                register for our Services, show interest in about us, our
                products and Services, participate in activities on our
                platform, or reach out to us in any other way.
              </p>
              <p>
                <strong>Personal Information Provided by You:</strong> The
                specific personal information we collect depends on your
                interactions with us and the Services, the choices you make, and
                the products or services and features you use. The personal
                information we may gather includes:
              </p>
              <ul>
                <li>Names</li>
                <li>Phone numbers</li>
                <li>Email addresses</li>
                <li>Mailing addresses</li>
                <li>Usernames</li>
                <li>Passwords</li>
                <li>Contact preferences</li>
                <li>Billing addresses</li>
                <li>Debit/credit card numbers</li>
                <li>Job titles</li>
                <li>Contact or authentication data</li>
              </ul>

              <h3>Sensitive Information</h3>
              <p>
                When necessary and with your consent or as allowed by applicable
                law, we may process the following categories of sensitive
                information:
              </p>
              <ul>
                <li>Financial data</li>
                <li>Biometric data</li>
                <li>Information about a person’s sexual life or orientation</li>
                <li>Creditworthiness data</li>
                <li>Social security numbers or other government identifiers</li>
                <li>Student data</li>
              </ul>

              <h3>Payment Data</h3>
              <p>
                If you choose to make purchases, we may collect data required to
                process your payment, such as your payment instrument number and
                the security code associated with it. All payment data is
                managed by [__________]. You can view their privacy policy
                [____].
              </p>

              <h3>Application Data</h3>
              <p>
                If you use our application(s), we may collect additional
                information (mentioned below) if you provide us access or
                permission, such as:
              </p>
              <ul>
                <li>
                  <strong>Geolocation Information:</strong> We may request
                  permission to access your mobile device’s location data to
                  provide certain location-based services. If you choose to
                  modify our access or permissions, you can modify these in your
                  device settings.
                </li>
                <li>
                  <strong>Mobile Device Access:</strong> We might request access
                  to specific features on your mobile device, such as Bluetooth,
                  calendar, camera, contacts, microphone, reminders, sensors,
                  SMS messages, storage, and others. You can change the
                  permissions you accept or reject in your device’s settings.
                </li>
                <li>
                  <strong>Mobile Device Data:</strong> We automatically collect
                  data about your device, including your mobile device ID,
                  model, manufacturer, operating system, version information,
                  system configuration information, browser type and version,
                  device and application identification numbers, hardware model,
                  Internet service provider, and/or mobile carrier, and IP
                  address (or proxy server). If you use our application(s), we
                  might also gather details about the phone network linked to
                  your mobile device, your device’s OS or platform, the type of
                  mobile device you use, your mobile device’s unique device ID,
                  and information of the features of our application(s) you
                  accessed.
                </li>
                <li>
                  <strong>Push Notifications:</strong> Regarding your account or
                  certain features, we may ask to send you push notifications.
                  You have the choice to opt out of these communications by
                  turning them push notifications by navigating your device’s
                  settings.
                </li>
              </ul>
              <p>
                This information is crucial for maintaining the security and
                functionality of our application(s), troubleshooting issues, and
                conducting internal analytics and reporting. All personal
                information you provide to us should be true, complete, and
                accurate. You are required to notify us if any changes are made
                to your personal information.
              </p>

              <h3>Information Automatically Collected</h3>
              <p>
                Some data, like your IP address and/or browser and device
                characteristics, is collected automatically when you interact
                with our Services. This data may include device and usage data,
                such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device
                name, country, location, and details about how and when you use
                our Services. This data is primarily used to ensure the security
                and operation of our Services and for internal analytics and
                reporting purposes.
              </p>
              <p>
                We also collect information through cookies and similar
                technologies. The information we gather includes:
              </p>
              <ul>
                <li>
                  <strong>Log and Usage Data:</strong> This data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and this data is also recorded in log
                  files. Depending on your interactions with us, this data may
                  include your IP address, device information, browser type, and
                  settings, as well as details about your activity in the
                  Services (such as the date/time stamps of your usage, pages
                  and files viewed, searches, and other actions you take, like
                  which features you use), device event information (like system
                  activity, error reports (sometimes called "crash dumps"), and
                  hardware settings).
                </li>
                <li>
                  <strong>Device Data:</strong> We collect data about the
                  computer, phone, tablet, or other device you use to access the
                  Services. Depending on the device, this data may include
                  details such as your IP address (or proxy server), device and
                  application identification numbers, location, browser type,
                  hardware model, Internet service provider and/or mobile
                  carrier, OS information and also system configuration data.
                </li>
                <li>
                  <strong>Location Data:</strong> We gather location data about
                  your device, which may be precise or imprecise. The amount of
                  information we collect depends on the type and settings of the
                  device you use to access the Services. For example, we may use
                  GPS and other technologies to collect geolocation data
                  indicating your current location (based on your IP address).
                  You can opt out of allowing us to collect this information by
                  refusing access to it or by disabling the Location setting on
                  your device. However, if you choose to opt out, some aspects
                  of the Services may not be available to you.
                </li>
                <li>
                  <strong>Google API:</strong> Our use of data received from
                  Google APIs will comply with the Google API Services User Data
                  Policy, including the Limited Use requirements.
                </li>
              </ul>

              <h3>Information Collected from Other Sources</h3>
              <p>
                To improve our applicable marketing, services, and offers that
                we provide to you and to update our records, we may acquire
                information about our users from various sources including
                public databases, joint marketing partners, affiliate programs,
                data providers, and third parties for targeted advertising and
                event promotion. This information may include:
              </p>
              <ul>
                <li>Mailing addresses</li>
                <li>Job titles</li>
                <li>Email addresses</li>
                <li>Phone numbers</li>
                <li>Intent data (or user behavior data)</li>
                <li>IP addresses</li>
                <li>Custom profiles</li>
              </ul>
              <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
              <p>
                Summary: We process your data to deliver, improve, and manage
                our Services, communicate with you, ensure security, prevent
                fraud, and comply with legal obligations. We may also process
                the information provided by you or collected automatically for
                various other purposes with your consent.
              </p>
              <p>
                We process your personal information for many different reasons,
                based on how you engage with our Services, such as:
              </p>
              <ul>
                <li>
                  <strong>
                    To facilitate account creation and authentication and manage
                    user accounts:
                  </strong>{" "}
                  We may process your information to help you create and log in
                  to your account and to keep your account functioning properly.
                </li>
                <li>
                  <strong>
                    To deliver and facilitate the delivery of services to the
                    user:
                  </strong>{" "}
                  We may process your data to provide the services you have
                  requested.
                </li>
                <li>
                  <strong>
                    To respond to user inquiries and offer support:
                  </strong>{" "}
                  We may process your information to respond to your questions
                  and resolve any issues you may encounter with our services.
                </li>
                <li>
                  <strong>To send you administrative information:</strong> We
                  may process your data to send you details about our products,
                  services, changes to our terms and policies, and other
                  relevant information.
                </li>
                <li>
                  <strong>To fulfill and manage your orders:</strong> We may
                  process your data to manage your orders, payments, returns,
                  and exchanges through our Services.
                </li>
                <li>
                  <strong>To enable user-to-user communications:</strong> We may
                  process your information if you choose to use features that
                  allow communication between users.
                </li>
                <li>
                  <strong>To request feedback:</strong> We may process your
                  information when needed to request feedback and to contact you
                  about your use of our Services.
                </li>
                <li>
                  <strong>
                    To send marketing and promotional communications:
                  </strong>{" "}
                  We may process the personal information you provide to send
                  marketing messages, if this aligns with your preferences. You
                  can unsubscribe to our marketing emails whenever you want. For
                  more details, you can visit the section: "WHAT ARE YOUR
                  PRIVACY RIGHTS?"
                </li>
                <li>
                  <strong>To deliver targeted advertising:</strong> We may
                  process your information to create and display personalized
                  content and ads tailored to your interests, location, and
                  more.
                </li>
                <li>
                  <strong>To post testimonials:</strong> We post testimonials on
                  our Services that may include personal information.
                </li>
                <li>
                  <strong>To protect our Services:</strong> We may process your
                  data as part of our efforts to maintain the security and
                  integrity of our Services, including monitoring for fraud.
                </li>
                <li>
                  <strong>To administer prize draws and competitions:</strong>{" "}
                  We may process your information to manage contests and prize
                  draws.
                </li>
                <li>
                  <strong>
                    To evaluate and improve our Services, products, marketing,
                    and your experience:
                  </strong>{" "}
                  We may process your information when it’s necessary to
                  identify usage trends, assess the success of our promotional
                  campaigns, and enhance our Services.
                </li>
                <li>
                  <strong>To comply with legal obligations:</strong> We may
                  process your information to meet legal obligations, such as
                  cooperating with law enforcement or regulatory bodies,
                  defending legal claims, or fulfilling our obligations related
                  to a contract.
                </li>
                <li>
                  <strong>
                    To enforce our terms, conditions, and policies for business
                    purposes:
                  </strong>{" "}
                  We may process your data to enforce our terms, conditions, and
                  policies as necessary.
                </li>
                <li>
                  <strong>
                    To respond to legal requests and prevent harm:
                  </strong>{" "}
                  If we receive a legal request, we may need to inspect the data
                  we hold to determine how to respond.
                </li>
                <li>
                  <strong>For other business purposes:</strong> We may process
                  your information for other business purposes, such as
                  analyzing data, identifying usage trends, determining the
                  effectiveness of our promotional campaigns, and evaluating and
                  enhancing our products, Services, marketing, and user
                  experience.
                </li>
              </ul>

              <h3>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </h3>
              <p>
                Summary: We may share your information under specific
                circumstances described in this section and/or with the
                following third parties.
              </p>
              <p>
                When we use Google Maps Platform APIs: We may share your data
                with certain Google Maps Platform APIs, such as Google Maps API
                and Places API. We may collect and store your location data on
                your device ("cache"). You can withdraw your consent at any time
                by reaching out to us using the contact information provided at
                the end of this document.
              </p>
              <p>
                <strong>Affiliates:</strong> We might share your information
                with our affiliates, requiring them to uphold this privacy
                policy. Affiliates include our parent company, subsidiaries,
                joint venture partners, or any other companies we control or are
                under common control with us.
              </p>
              <p>
                <strong>Business Partners:</strong> We might share your
                information with our partners to deliver you certain products,
                services, and promotional deals and offers.
              </p>
              <p>
                <strong>Offer Wall:</strong> Our application(s) may include a
                third-party hosted "offer wall." This offer wall allows
                third-party advertisers to provide virtual currency, gifts, or
                other incentives to users in exchange for completing an
                advertisement offer. This offer wall might appear in our
                application(s) and be shown to you based on data like your
                geographic location or demographic details. If you click on an
                offer wall, you will be redirected to an external site operated
                by third parties, and you will leave our application(s). A
                unique identifier, such as your user ID, will be shared with the
                offer wall provider to prevent fraud and correctly credit your
                account with the relevant reward.
              </p>

              <h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
              <p>
                Summary: We may use cookies and other tracking technologies to
                collect and store your data.
              </p>
              <p>
                We may employ cookies and similar tracking technologies (such as
                web beacons and pixels) to gather information when you interact
                with our Services. These online tracking technologies assist us
                in maintaining the security of our Services and your account,
                preventing crashes, fixing bugs, saving your preferences, and
                aiding with basic site functions.
              </p>
              <p>
                To the extent that these online tracking technologies are
                considered a "sale"/"sharing" (which includes targeted
                advertising as defined by applicable laws) under relevant U.S.
                state laws, you can opt out by submitting a request as detailed
                in the section "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                RIGHTS?" below.
              </p>
              <p>
                For more specific details on how we use these technologies and
                how you can refuse certain cookies, please refer to our Cookie
                policy.
              </p>
              <p>
                <strong>Google Analytics:</strong> We may share your data with
                Google Analytics to monitor and analyze the use of the Services.
                The Google Analytics Advertising Features we might use include
                Google Analytics Demographics and Interests Reporting. To opt
                out of getting tracked by Google Analytics when using our
                Services, you can visit Google Analytics Opt-Out. You can also
                opt out of Google Analytics Advertising Features through Ads
                Settings and Ad Settings for mobile apps, or by visiting Network
                Advertising Opt-Out and Mobile Choice. For further details on
                Google's privacy practices, visit the Google Privacy & Terms
                page.
              </p>
              <h3>5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</h3>
              <p>
                In Short: We provide products, features, or tools powered by
                technologies such as artificial intelligence (AI), machine
                learning (ML), and more.
              </p>
              <p>
                Our Services include products, features, or tools powered by
                artificial intelligence, machine learning, or related
                technologies (collectively referred to as "AI Products"). These
                tools are meant to improve your experience and deliver
                innovative solutions. The terms outlined in this privacy policy
                govern your use of the AI Products within our Services.
              </p>
              <p>
                <strong>Our AI Products:</strong> Our AI Products are designed
                for functions such as providing AI insights.
              </p>
              <p>
                <strong>How We Process Your Data Using AI:</strong> All personal
                data processed using our AI Products is managed in accordance
                with our privacy policy and agreements with third parties. This
                ensures your personal data is handled with high security,
                providing you with peace of mind about the safety of your
                information.
              </p>

              <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
              <p>
                In Short: We retain your information for the necessary duration
                for the purposes mentioned in this privacy policy unless a
                longer retention period for information is legally required.
              </p>
              <p>
                We will retain your personal data only for as long as necessary
                to achieve the purposes outlined in this privacy policy, unless
                a longer retention period is mandated or permitted by law (such
                as tax, accounting, or other legal purposes). No purpose
                outlined in this policy will require us to retain your personal
                data for longer than the period during which you maintain an
                account with us.
              </p>
              <p>
                When we no longer have a legitimate business need to process
                your personal information, we will either delete or anonymize
                it. If this is not possible (for instance, if your personal data
                has been stored in backup archives), we will securely store your
                personal information and isolate it from further processing
                until deletion is possible.
              </p>

              <h3>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
              <p>
                Summary: We strive to protect your personal information through
                a combination of organizational and technical security measures.
              </p>
              <p>
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to safeguard the
                personal information we process. However, despite our efforts
                and the measures we take to secure your data, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure. Therefore, we cannot
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to breach our security and
                improperly access, steal, or modify your data. Although we do
                our utmost to protect your personal information, the
                transmission of data to and from our Services is at your own
                risk. You should access and use our Services only within a
                secure environment.
              </p>

              <h3>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
              <p>
                In Short: We do not knowingly collect data from or market to
                minors (children under 18 years of age).
              </p>
              <p>
                We do not knowingly collect or solicit data from, or market to,
                children under 18 years of age. Nor do we knowingly sell
                personal information related to children under this age. By
                using the Services, you affirm that you are at least 18 years
                old, or that you are the parent or guardian of a minor under 18,
                and consent to their use of the Services. If we discover that we
                have collected personal data from users under 18, we will
                deactivate the account and take appropriate steps to promptly
                delete such information from our records. If you become aware of
                any data we may have collected from children under 18, please
                contact us at support@afnocorp.com or support@pocketnest.com.
              </p>

              <h3>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
              <p>
                Summary: You may review, modify, or terminate your account
                whenever you want, based on your country, province, or state of
                residence.
              </p>
              <p>
                <strong>Withdrawing your consent:</strong> If our processing of
                your personal information is based on your consent, whether
                express or implied depending on the applicable law, you have the
                right to withdraw that consent at any time. You can withdraw
                your consent by reaching out to us using the details provided in
                the section "HOW CAN YOU CONTACT US ABOUT THIS POLICY?" below.
              </p>
              <p>
                Please note, however, that withdrawing your consent will not
                affect the lawfulness of any processing carried out before your
                withdrawal, nor will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent, where permitted by applicable law.
              </p>
              <p>
                <strong>
                  Opting out of marketing and promotional communications:
                </strong>{" "}
                Reply "STOP" or "UNSUBSCRIBE" to SMS messages, or reach out to
                us by using the information shared in the section "HOW CAN YOU
                CONTACT US ABOUT THIS POLICY?" to opt out of our marketing and
                promotional communications at any time by clicking the
                unsubscribe link in the emails we send. You will then be removed
                from our marketing lists, although we may still communicate with
                you for non-marketing purposes, such as service-related messages
                necessary for use and account administration or to respond to
                your requests.
              </p>
              <p>
                <strong>Account Information:</strong> If you would like to
                review or change the information in your account or terminate
                your account at any time, you can do so by contacting us using
                the provided contact information.
              </p>
              <p>
                Once you request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, some of the information may still remain in
                our files to prevent fraud, troubleshoot issues, assist with
                investigations, enforce our legal terms, and/or comply with
                applicable legal requirements.
              </p>
              <p>
                <strong>Cookies and similar technologies:</strong> Most web
                browsers accept cookies by default. However, if you prefer, you
                can usually choose to set your browser to remove cookies or
                reject them. If you decide to remove or reject cookies, it could
                impact certain features or functionalities of our Services.
              </p>
              <p>
                If you have any questions or comments regarding your privacy
                rights, you can email us at support@afnocorp.com or
                support@pocketnest.com.
              </p>

              <h3>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
              <p>
                Most web browsers and some mobile operating systems and
                applications include a Do-Not-Track (“DNT”) feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. As of now, there is no uniform technology or standard
                to identify and implement DNT signals. Therefore, as of now, we
                do not respond to DNT browser signals or any other mechanism
                that automatically communicates your choice of “Do Not Track”
                online. Should a standard for online tracking be adopted that we
                must follow in the future, we will inform you about that
                practice in an updated version of this privacy policy.
              </p>
              <p>
                California law mandates us to let you know how we handle web
                browser DNT signals. Since there is currently no industry or
                legal standard to recognize or honor DNT signals, we do not
                respond to them at this time.
              </p>
              <h3>
                11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </h3>
              <p>
                <strong>Summary:</strong> If you are a resident of California,
                Colorado, Connecticut, Delaware, Florida, Indiana, Iowa,
                Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee,
                Texas, Utah, or Virginia, you may have specific rights regarding
                your personal information. These rights may include requesting
                access to, receiving details about, correcting inaccuracies in,
                obtaining a copy of, or deleting the personal information we
                maintain about you. Additionally, you may have the right to
                withdraw your consent to our processing of your personal data.
                Some of these rights may be restricted by applicable laws in
                some circumstances. Further details are provided below.
              </p>

              <h4>Categories of Personal Information We Collect</h4>
              <table >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Examples</th>
                    <th>Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A. Identifiers</td>
                    <td>
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      B. Personal information as defined in the California
                      Customer Records statute
                    </td>
                    <td>
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      C. Protected classification characteristics under state or
                      federal law
                    </td>
                    <td>
                      Gender, age, date of birth, race and ethnicity, national
                      origin, marital status, and other demographic data
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>D. Commercial information</td>
                    <td>
                      Transaction information, purchase history, financial
                      details, and payment information
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>E. Biometric information</td>
                    <td>Fingerprints and voiceprints</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>F. Internet or other similar network activity</td>
                    <td>
                      Browsing history, search history, online behavior,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>G. Geolocation data</td>
                    <td>Device location</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      H. Audio, electronic, sensory, or similar information
                    </td>
                    <td>
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>I. Professional or employment-related information</td>
                    <td>
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>J. Education Information</td>
                    <td>Student records and directory information</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      K. Inferences drawn from collected personal information
                    </td>
                    <td>
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>L. Sensitive personal Information</td>
                    <td>
                      Account login information, citizenship or immigration
                      status, biometric data, contents of email or text
                      messages, debit or credit card numbers, drivers' licenses,
                      financial information including account access details,
                      racial or ethnic origin and social security numbers
                    </td>
                    <td>YES</td>
                  </tr>
                </tbody>
              </table>

              <h4>Categories of Personal Information We Collect</h4>
              <p>
                We gather sensitive personal information only as defined by
                applicable privacy laws, and only for purposes allowed by law or
                with your consent. This sensitive personal information may be
                used or disclosed to a service provider or contractor for
                specific additional purposes. You may also have the right to
                restrict the use or disclosure of your sensitive personal
                information.
              </p>
              <p>
                In addition to these categories, we may collect other personal
                information when you interact with us in person, online, by
                phone, or by mail, particularly in the context of:
              </p>
              <ul>
                <li>
                  Receiving support through our customer service channels;
                </li>
                <li>Participating in customer surveys or contests;</li>
                <li>
                  Assisting in the delivery of our Services and responding to
                  your inquiries.
                </li>
              </ul>
              <p>
                We will use and retain the collected personal information for
                the duration required to provide the Services or for:
              </p>
              <ul>
                <li>
                  Category A: For as long as you maintain an account with us;
                </li>
                <li>
                  Category B: For as long as you maintain an account with us;
                </li>
                <li>
                  Category C: For as long as you maintain an account with us;
                </li>
                <li>
                  Category D: For as long as you maintain an account with us;
                </li>
                <li>
                  Category E: For as long as you maintain an account with us;
                </li>
                <li>
                  Category F: For as long as you maintain an account with us;
                </li>
                <li>
                  Category G: For as long as you maintain an account with us;
                </li>
                <li>
                  Category I: For as long as you maintain an account with us;
                </li>
                <li>
                  Category K: For as long as you maintain an account with us;
                </li>
                <li>
                  Category L: For as long as you maintain an account with us.
                </li>
              </ul>
              <p>
                <strong>Sources of Personal Information:</strong> To learn more
                about the sources from which we collect personal information,
                please refer to the section "WHAT INFORMATION DO WE COLLECT?"
              </p>
              <p>
                <strong>How We Use and Share Personal Information:</strong>{" "}
                Details on how we utilize your personal information can be found
                in the section "HOW DO WE PROCESS YOUR INFORMATION?"
              </p>
              <p>
                <strong>
                  Will Your Information Be Shared With Anyone Else?
                </strong>{" "}
                We may share your personal information with our service
                providers, as outlined in a written agreement between us and
                each service provider. More information on this can be found in
                the section "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                INFORMATION?"
              </p>
              <p>
                We may also use your personal information for internal business
                purposes, such as conducting research for technological
                development and demonstration. This is not deemed a "sale" of
                your personal information.
              </p>
              <p>
                In the past twelve (12) months, we have not disclosed, sold, or
                shared any personal data or information with third parties for
                business or commercial gains. In the future, we will not sell or
                share personal information belonging to website visitors, users,
                or other consumers.
              </p>

              <h4>Your Rights</h4>
              <p>
                Under specific US state data protection laws, you have certain
                rights related to personal data. However, these rights are not
                absolute, and in some circumstances, we may not accept your
                request as allowed legally. These rights include:
              </p>
              <ul>
                <li>
                  The right to know whether we are processing your personal
                  data;
                </li>
                <li>The right to access your personal data;</li>
                <li>
                  The right to correct inaccuracies in your personal data;
                </li>
                <li>
                  The right to request the deletion of your personal data;
                </li>
                <li>
                  The right to obtain a copy of the personal data you previously
                  shared with us;
                </li>
                <li>
                  The right to non-discrimination for exercising your rights;
                </li>
                <li>
                  The right to opt out of the processing of your personal data
                  if it is used for targeted advertising (or sharing as defined
                  under California’s privacy law), the sale of personal data, or
                  profiling that results in decisions with legal or similar
                  significant effects ("profiling").
                </li>
              </ul>
              <p>
                Depending on your state of residence, you may also have the
                following rights:
              </p>
              <ul>
                <li>
                  The right to obtain a list of categories of third parties to
                  whom we have disclosed personal data (as allowed by applicable
                  law, such as California's and Delaware's privacy law);
                </li>
                <li>
                  The right to obtain a list of specific third parties to whom
                  we have disclosed personal data (as allowed by applicable law,
                  such as Oregon’s privacy law);
                </li>
                <li>
                  The right to limit the use and disclosure of sensitive
                  personal data (as permitted by applicable law, such as
                  California’s privacy law);
                </li>
                <li>
                  The right to opt out of the collection of sensitive data and
                  personal data through the use of voice or facial recognition
                  features (as allowed by applicable law, such as Florida’s
                  privacy law).
                </li>
              </ul>

              <h4>How to Exercise Your Rights</h4>
              <p>
                To exercise your rights, you can submit a data subject access
                request by emailing us at{" "}
                <a href="mailto:support@afnocorp.com">support@afnocorp.com</a>{" "}
                or{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
                , visiting{" "}
                <a href="https://budzet.io/contact">
                  https://budzet.io/contact
                </a>
                , or using the contact information provided at the end of this
                document.
              </p>
              <p>
                If you enable the Global Privacy Control (GPC) opt-out signal on
                your browser, we will honor your opt-out preferences.
              </p>
              <p>
                Under certain US state data protection laws, you can appoint an
                authorized agent to make a request on your behalf. We may deny a
                request from an authorized agent who does not provide proof that
                they have been validly authorized to act on your behalf, in
                accordance with applicable laws.
              </p>

              <h4>Request Verification</h4>
              <p>
                Upon receiving your request, we may need to verify your identity
                to confirm that you are the individual whose information is in
                our system. We will use personal information provided in your
                request solely to verify your identity or authority to make the
                request. If we cannot verify your identity with the information
                we already maintain, we may ask you to provide additional
                information for verification purposes and for security or fraud
                prevention.
              </p>
              <p>
                If a request is submitted by an authorized agent, we may require
                additional information to verify your identity. The agent must
                provide written and signed permission from you, authorizing them
                to submit the request on your behalf.
              </p>

              <h4>Appeals</h4>
              <p>
                Under certain US state data protection laws, if we decline to
                take action regarding your request, you may appeal our decision
                by emailing us at{" "}
                <a href="mailto:support@afnocorp.com">support@afnocorp.com</a>{" "}
                or{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>
                . We will tell of you any action taken or not taken in writing
                in response to your appeal. This includes a written explanation
                with the reasons for the decisions we take. You may also file a
                complaint to your state attorney general if your appeal is
                denied.
              </p>

              <h4>California "Shine The Light" Law</h4>
              <p>
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, allows California residents to request and
                receive, once a year and free of charge, information about
                categories of personal information (if any) we disclosed to
                third parties for direct marketing purposes and the names and
                addresses of all third parties with whom we shared personal
                information in the previous calendar year. If you are a
                California resident and wish to make such a request, please
                submit it in writing using the contact information provided in
                the section "HOW CAN YOU CONTACT US ABOUT THIS POLICY?"
              </p>

              <h3>13. DO WE MAKE UPDATES TO THIS POLICY?</h3>
              <p>
                <strong>In Short:</strong> Yes, we will update this policy as
                necessary to remain in compliance with relevant laws.
              </p>
              <p>
                We may periodically update this privacy policy. The updated
                version will be indicated by a revised date at the top of this
                policy. If we make significant changes to this policy, we may
                notify you either by posting a prominent policy or by directly
                sending you a notification. We encourage you to review this
                privacy policy frequently to stay informed about how we protect
                your information.
              </p>

              <h3>14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
              <p>
                If you have any questions or comments about this policy, you can
                email us at{" "}
                <a href="mailto:support@afnocorp.com">support@afnocorp.com</a>{" "}
                or{" "}
                <a href="mailto:support@pocketnest.com">
                  support@pocketnest.com
                </a>{" "}
                or contact us by mail at:
              </p>
              <p>
                Afno Financial Holdings Corporation
                <br />
                1612 Coastal Hwy
                <br />
                Lewes, DE 19958
                <br />
                United States
              </p>

              <h3>
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h3>
              <p>
                Depending on the laws of your country or state of residence in
                the US, you may have the right to request access to, review,
                correct, or delete the personal data we gather from you and
                details about how we processed it. You may also have the right
                to withdraw your consent to our processing of your personal
                information. These rights may be limited by applicable law in
                certain scenarios. To request to review, update, or delete your
                personal information, please submit a data subject access
                request.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
