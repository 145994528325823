import React, { useState } from "react";
import { Helmet } from 'react-helmet';

const Pricing = () => {
  const [selectedPlan, setSelectedPlan] = useState("monthly"); // Default to 'monthly'

  // Handle selection of plan
  const handlePlanSelection = (event) => {
    setSelectedPlan(event.target.value);
  };

  // Handle trial start button click
  const handleTrialStart = () => {
    if (selectedPlan === "annual") {
      window.open(
        "https://budzet.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Budzet-Annual-and-Monthly-Plans-USD-Yearly&utm_source=cb-app-copy",
        "noopener noreferrer"
      );
    } else if (selectedPlan === "monthly") {
      window.open(
        "https://budzet.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Budzet-Annual-and-Monthly-Plans-USD-Monthly&utm_source=cb-app-copy",
        "noopener noreferrer"
      );
    }
  };

  return (
    <>
    <Helmet>
        <title>Pricing – Financial Wellness for a price less than a cup of coffee</title>
        <meta
          name="description"
          content="Explore our flexible subscription options. Choose between an annual or a monthly plan to access the best budgeting and financial tools. SIGN UP NOW for a free 30-day trial today and take control of your finances."
        />
      </Helmet>
      <div className="InnerPageBanner">
        <div className="container">
          <h1>Pricing</h1>
        </div>
      </div>

      <div className="pricingpage">
        <div className="container">
          <div className="pricingpageWrap">
            <div className="pricingpageWrapInner">
              <div className="pricingtop">
                <h2>
                  Unlock
                  <br />
                  Financial Freedom
                </h2>
                <p>
                  Join Budzet and start your <br /> journey today
                </p>
              </div>
              <div className="pricingAnnual">
                <div className="annualtoprow">
                  <div className="annualtoprowText annulatextLhs">
                    <label>
                      <input
                        type="radio"
                        value="annual"
                        checked={selectedPlan === "annual"}
                        onChange={handlePlanSelection}
                      />{" "}
                      Annual Plan
                    </label>
                  </div>
                  <div className="annualtoprowText annualtextRhs">
                    <label>
                      $49.99* per year
                    </label>
                  </div>
                </div>
               
                  <div className="annualsavingrow">
                    <div className="savingtag">
                      <div className="savingtagin">20% Savings!</div>
                    </div>
                  </div>
              
              </div>

              <div className="Monthlyplanrow">
                <div className="Monthlyplanlhs">
                  <label>
                    <input
                      type="radio"
                      value="monthly"
                      checked={selectedPlan === "monthly"}
                      onChange={handlePlanSelection}
                    />{" "}
                    Monthly Plan
                  </label>
                </div>
                <div className="Monthlyplanrhs">
                  <label>
                    $4.99* per month
                  </label>
                </div>
              </div>

              <div className="smalldetailsText">
                *USD (plus taxes where applicable)
              </div>
              <div className="pricingboxcta">
                <button
                  className="cta"
                  onClick={handleTrialStart}
                  disabled={!selectedPlan} // Disable button until a plan is selected
                >
                  Start Your Free 30-Days Trial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
